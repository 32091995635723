import React from "react";
import { Button } from "semantic-ui-react";
import AuthenticatorHeader from "./AuthenticatorHeader";

const TurnOnP2FaPrompt = ({ onProceed, onSkip }) => {
  return (
    <section>
      <AuthenticatorHeader title="Turn on 2-Step Verification" />
      <p>
        Using 2FA helps keep your accounts safe by requiring a second step to
        verify your identity, making it much harder for anyone else to access
        your information. We strongly suggest you set up 2FA.
      </p>
      <div className="split-buttons">
        <Button primary onClick={onProceed} fluid size="large">
          Proceed to setup 2FA
        </Button>

        <Button onClick={onSkip} fluid basic size="large">
          Skip 2FA setup
        </Button>
      </div>
    </section>
  );
};

export default TurnOnP2FaPrompt;
