import React from "react";
import { Header } from "semantic-ui-react";

const AuthenticatorHeader = ({ title }) => {
  return (
    <header className="header">
      <Header as="h2">{title}</Header>
    </header>
  );
};

export default AuthenticatorHeader;
