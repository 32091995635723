import React from "react";
import { useFormField, Form } from "@redriver/cinnamon";
import VerificationInput from "react-verification-input";
import { CustomErrors } from "components/forms";

const AuthCodeInputBox = ({ field }) => {
  const [value, setValue] = useFormField(field);
  return (
    <Form.Field label="Authentication Code" required className="auth-code-box">
      <VerificationInput
        required
        onChange={setValue}
        value={value}
        length={6}
        autoFocus
        validChars="0-9"
        placeholder=""
        inputProps={{ inputMode: "numeric" }}
        classNames={{
          container: "code-container",
          character: "code-character",
          characterInactive: "code-character-inactive",
          characterSelected: "code-character-selected",
          characterFilled: "code-character-filled",
        }}
      />
      <CustomErrors
        field={field}
        triggerFields={[field]}
        validator={({ fields }) => {
          if (!fields[field] || fields[field].length != 6) {
            return ["Authentication code is required"];
          }
          return [];
        }}
      />
    </Form.Field>
  );
};

export default AuthCodeInputBox;
