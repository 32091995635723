import React from "react";
import { Lookup, Form } from "@redriver/cinnamon";
import { eventLocationTypeLookup } from "modules/lookups";

const EventLocationTypeCheckboxGroup = (props) => (
  <Lookup
    lookup={eventLocationTypeLookup}
    render={({ response }) => (
      <Form.CheckboxGroup {...props} options={response || []} />
    )}
  />
);

export default EventLocationTypeCheckboxGroup;
