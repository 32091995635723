import React, { useState, useMemo, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Loader, Checkbox, Header } from "semantic-ui-react";
import {
  FormBuilder,
  Form,
  ErrorMessage,
  authenticate,
} from "@redriver/cinnamon";
import { AppRoutes, SystemRoutes } from "constants/routes";
import { requestRegistration, completeRegistration } from "./actions";
import RecoveryCodes from "./RecoveryCodes";
import AuthenticatorHeader from "./AuthenticatorHeader";
import CodeEntry from "./CodeEntry";
import Setup2FaAccount from "./Setup2FaAccount";
import TurnOnP2FaPrompt from "./TurnOnP2FaPrompt";

const DEFAULT_MFA_RECOVERY_TYPE = "RecoveryCodes";
const MFA_SETUP_TIMEOUT = 1000 * 60 * 25; // 25 mins

const authenticatorErrors = {
  922001: "Incorrect authenticator code, please try again",
};

const SetupStages = {
  Prompt: "Prompt",
  Setup: "Setup",
  CodeEntry: "CodeEntry",
};

const AuthenticatorSetup = ({
  token,
  loginTokens,
  setupRecovery,
  onSkipped,
}) => {
  const [setupFailed, setSetupFailed] = useState(false);
  const [setupSuccess, setSetupSuccess] = useState(false);
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const [codesSaved, setCodesSaved] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [stage, setStage] = useState(SetupStages.Prompt);
  const loginTokensIssuedAt = useMemo(() => Date.now(), [loginTokens]);

  useEffect(() => {
    if (setupFailed || setupSuccess) return;
    // kick back to login after 25 mins before mfa/refresh tokens expire
    const timeout = setTimeout(() => {
      history.replace(SystemRoutes.Login);
    }, MFA_SETUP_TIMEOUT);
    return () => {
      clearTimeout(timeout);
    };
  }, [setupFailed, setupSuccess]);

  if (setupFailed) {
    return (
      <section>
        <AuthenticatorHeader title="2-Step Verification" />
        <p>Two-Factor Authentication setup failed.</p>
        <p>
          Return to <Link to={SystemRoutes.Login}>Log In</Link>
        </p>
      </section>
    );
  }

  if (setupSuccess) {
    const hasCodes = recoveryCodes?.length > 0;
    return (
      <section>
        <AuthenticatorHeader title="2-Step Verification" />
        <p>Your account has been successfully setup.</p>
        {hasCodes && <RecoveryCodes codes={recoveryCodes} />}
        <div style={{ marginTop: 20 }}>
          {hasCodes && (
            <Checkbox
              label="I have saved my recovery codes"
              checked={codesSaved}
              onChange={() => setCodesSaved(!codesSaved)}
            />
          )}
          <Button
            primary
            fluid
            as={Link}
            to={AppRoutes.Root}
            replace
            size="large"
            disabled={hasCodes && !codesSaved}
          >
            Continue
          </Button>
        </div>
      </section>
    );
  }

  const skipSetup = () => {
    if (!loginTokens) return;
    const { access, refresh } = loginTokens;
    // this may happen some time after the tokens were issued
    // so need to specify the (local browser) time they were received
    dispatch(
      authenticate(access, refresh, { tokenIssuedAt: loginTokensIssuedAt })
    );
    if (onSkipped) onSkipped();
  };

  return (
    <FormBuilder
      loadAction={requestRegistration}
      loadParams={token}
      onLoaded={(response) => {
        if (!response?.secret) setSetupFailed(true);
      }}
      submitAction={completeRegistration}
      submitParams={{
        token,
        recovery: setupRecovery ? DEFAULT_MFA_RECOVERY_TYPE : undefined,
      }}
      onSubmitted={({ codes }) => {
        if (codes) setRecoveryCodes(codes);
        setSetupSuccess(true);
      }}
      renderLoading={false}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { loading, slowLoading, submitting, slowSubmitting, error },
        events
      ) => {
        const { secret } = formProps.value;
        if (loading && slowLoading)
          return <Loader inline active size="large" />;
        if (!secret) return null;

        return (
          <Form {...formProps}>
            {stage == SetupStages.Prompt && (
              <TurnOnP2FaPrompt
                onProceed={() => setStage(SetupStages.Setup)}
                onSkip={skipSetup}
              />
            )}
            {stage == SetupStages.Setup && (
              <Setup2FaAccount
                secret={secret}
                onProceed={() => setStage(SetupStages.CodeEntry)}
                onBack={() => setStage(SetupStages.Prompt)}
              />
            )}
            {stage == SetupStages.CodeEntry && (
              <CodeEntry
                onProceed={events.onSubmit}
                onBack={() => setStage(SetupStages.Setup)}
                submitting={submitting}
                slowSubmitting={slowSubmitting}
              />
            )}

            <ErrorMessage
              header="Authentication Error"
              error={error}
              overrideMessages={authenticatorErrors}
              omitCodes
            />

            <p>
              Return to <Link to={SystemRoutes.Login}>Log In</Link>
            </p>
          </Form>
        );
      }}
    />
  );
};

export default AuthenticatorSetup;
