import React from "react";
import { Lookup } from "@redriver/cinnamon";
import { eventLocationTypeLookup } from "modules/lookups";
import { CascadingRadioGroup } from "./fields";
import { EventLocationType, EventFormat } from "constants/enums";

const getOptions = (options, eventFormat) =>
  (options || []).map((o) => {
    if (
      eventFormat &&
      eventFormat === EventFormat.Virtual &&
      o.value === EventLocationType.External
    ) {
      return { value: o.value, text: `${o.text}/${EventFormat.Virtual}` };
    }
    return o;
  });

const EventLocationTypeRadioGroup = ({ eventFormat, ...props }) => (
  <Lookup
    lookup={eventLocationTypeLookup}
    render={({ response }) => {
      const options = getOptions(response, eventFormat);
      return <CascadingRadioGroup {...props} options={options || []} />;
    }}
  />
);

export default EventLocationTypeRadioGroup;
