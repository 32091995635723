import React from "react";
import QRCode from "qrcode.react";
import AuthenticatorHeader from "./AuthenticatorHeader";
import { ExternalUrls } from "constants/urls";
import { Button } from "semantic-ui-react";

const Setup2FaAccount = ({ onProceed, onBack, secret }) => {
  return (
    <section className="auth-setup-2fa">
      <AuthenticatorHeader title="Step 1" />
      <p>Scan the image below with your 2FA authenticator app.</p>
      <div className="auth-content-column">
        <QRCode value={ExternalUrls.AuthenticatorQrCode(secret)} size={256} />
        <p className="auth-or-text">OR</p>
      </div>
      <p>
        Manually enter the following key into your chosen authenticator app:
      </p>
      <p className="key">{secret}</p>
      <Button primary onClick={onProceed} fluid size="large">
        Continue
      </Button>
      <Button basic onClick={onBack}>
        Back
      </Button>
    </section>
  );
};

export default Setup2FaAccount;
