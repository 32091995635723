import React from "react";
const { AuthCodeInputBox } = require("components/forms");
const { default: AuthenticatorHeader } = require("./AuthenticatorHeader");
const { Button } = require("semantic-ui-react");

const CodeEntry = ({ onProceed, onBack, submitting, slowSubmitting }) => {
  return (
    <section>
      <AuthenticatorHeader title="Step 2" />
      <p>Enter the 6-digit code from your authenticator app below.</p>
      <AuthCodeInputBox field="totp" />
      <Button
        primary
        onClick={onProceed}
        fluid
        size="large"
        disabled={submitting}
        loading={submitting && slowSubmitting}
      >
        Enable 2FA
      </Button>
      <Button basic onClick={onBack}>
        Back
      </Button>
    </section>
  );
};

export default CodeEntry;
